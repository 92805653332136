import axios from "axios"
import React from "react"
import EpisodePlayer from "../../components/EpisodePlayer"
import { Button, Form, TextInput } from "../../components/Form"
import { Column, Container, Row } from "../../components/Grid"
import Hero from "../../components/Hero"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import OutboundLink from "../../components/OutboundLink"
import Quicklinks from "../../components/Quicklinks"
import Section from "../../components/Section"
import SEO from "../../components/Seo"
import { AuthConsumer } from "../../context/AuthContext"
import SchoolLookup from "../../images/theme/img-pharmacists-school.jpg"
import SubFooterImage from "../../images/theme/img-subfooter-pharmacists.jpg"
import { login } from "../../utils/auth"

class PharmacistsSpanishPage extends React.Component {
  state = {
    zip: "",
    schools: [],
    error: null,
    isClient: false,
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  handleZipChange = (field, value) => {
    this.setState({ zip: value })
  }

  handleFormSubmit = () => {
    const { zip } = this.state

    const validated = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)

    if (!validated) {
      this.setState({
        error: "Please enter a valid zip code.",
      })
    } else {
      axios
        .get(`/api/schools.php`, {
          params: {
            zip,
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(schools => {
          if (schools.data.data.length === 0) {
            this.setState({
              error: `No schools were found in ${zip}.`,
            })
          } else {
            this.setState({
              schools: schools.data.data,
              error: null,
            })
          }
        })
        .catch(error => {
          this.setState({
            error: `We encountered an issue searching for schools in ${zip}. Please try again.`,
          })
        })
    }
  }

  handleFormReset = () => {
    this.setState({
      error: null,
      schools: [],
      zip: "",
    })
  }

  render() {
    const { zip, error, schools, isClient } = this.state

    return (
      <Layout>
        <SEO title="Farmacéuticos" />
        {/***************  HERO ***************/}
        <Section className="hero">
          <Hero
            title="Farmacéuticos"
            body="El programa Los farmacéuticos educan fue diseñado para ofrecer a los farmacéuticos de CVS recursos para impartir el programa Dose of Knowledge (Una dosis de conocimiento) en el aula y así iniciar una conversación sobre el abuso de sustancias, el uso seguro de los medicamentos y la salud mental."
            infographic="img-hero-infographic-pharmacists.png"
            image="img-hero-pharmacists.jpg"
          />
        </Section>
        <AuthConsumer>
          {isAuthenticated => (
            <>
              {/***************  SSO CALLOUT  ***************/}
              {isAuthenticated && isClient ? null : (
                <Section className="sso__callout midgray-background">
                  <Container>
                    <button onClick={login}>
                      Farmacéuticos de CVS, inicien sesión utilizando sus
                      credenciales de inicio único de CVS para encontrar una
                      escuela cercana y preparar su lección hoy mismo.{" "}
                      <Icon name="rightarrow" />
                    </button>
                  </Container>
                </Section>
              )}

              {/***************  SCHOOL LOOKUP ***************/}
              <Section className="school-lookup midgray-background">
                <Container>
                  <Row>
                    <Column span={6}>
                      <img
                        src={SchoolLookup}
                        className="school-lookup"
                        alt="SSO School Lookup"
                      />
                    </Column>
                    <Column span={6}>
                      <h2>Encontrar una escuela</h2>
                      {schools.length > 0 ? (
                        <button
                          className="as-link"
                          onClick={this.handleFormReset}
                        >
                          &lsaquo; Buscar de nuevo
                        </button>
                      ) : (
                        <p>
                          Ingrese su código postal para encontrar escuelas
                          cercanas donde le gustaría recibir el programa Los
                          farmacéuticos educan.
                        </p>
                      )}

                      {error && <p className="error-message">{error}</p>}

                      {schools.length > 0 && (
                        <div className="school-lookup-results">
                          {schools.map(school => (
                            <div className="school">
                              <h3>{school.inst}</h3>
                              <div>
                                {school.mstreet}
                                <br />
                                {school.mcity}, {school.mstate}{" "}
                                {school.mzipcode}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {schools.length === 0 && (
                        <Form onSubmit={this.handleFormSubmit}>
                          <TextInput
                            type="text"
                            label="Zip Code"
                            name="zip"
                            placeholder={
                              isAuthenticated && isClient
                                ? "Código postal"
                                : "Iniciar sesión para encontrar una escuela"
                            }
                            value={zip}
                            labelHidden
                            onChange={
                              !isAuthenticated ? null : this.handleZipChange
                            }
                            disabled={
                              isAuthenticated && isClient ? false : true
                            }
                          />
                          <Button
                            disabled={
                              isAuthenticated && isClient ? false : true
                            }
                            className="global__button red"
                          >
                            {isAuthenticated && isClient
                              ? "Encontrar una escuela"
                              : "Iniciar sesión para encontrar una escuela"}
                          </Button>
                        </Form>
                      )}
                    </Column>
                  </Row>
                </Container>
              </Section>

              <Section className="midgray-background">
                <Container>
                  <Row>
                    <Quicklinks
                      title="Enlaces rápidos:"
                      className="educators__quicklinks"
                      links={[
                        {
                          target: "#K-5",
                          label: "K–5",
                        },
                        {
                          target: "#6-8",
                          label: "6–8",
                        },
                        {
                          target: "#9-12",
                          label: "9–12",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************** Grados K-5 DLBs *****************/}
              <Section className="midgray-background" id="K-5">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grados K–5"
                      episodes={[
                        {
                          title: "El uso seguro de los medicamentos (K–2)",
                          videoDuration: "5:14 minutos",
                          personDescription:
                            "Conoceremos a Dani, una farmacéutica de CVS que nos explicará qué son los medicamentos, cómo tomarlos de forma segura y qué hacer ante una emergencia.",
                          gradeRange: "Grados K–2",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes conocerán el poder de los medicamentos y la importancia de utilizarlos de forma segura. Aprenderán sobre los medicamentos recetados, los de venta libre y las vitaminas, y sabrán cómo actuar en caso de emergencia.",
                          actions: [
                            {
                              type: "download",
                              label: "Lección Digital",
                              path: "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Medication-Safety-Pharmacist-V5.0_ES.pptx",
                              size: "PPT 703MB",
                              className: "episode-player__action-text",
                              logName: "Medication-Safety-K-2-Pharmacist-ES.pptx",
                            },
                            {
                              type: "download",
                              label: "Guia para el farmacéutico",
                              path: "/docs/CVS-Pharma-DLB-MedicationSafety-Pharmacist-V4.0.pdf",
                              size: "PDF 4.8MB",
                              className: "episode-player__action-text",
                              logName: "Medication-Safety-K-2-Pharmacist.pdf",
                            },
                          ],
                          guidSrc: "2416cbec-11ad-46f0-b885-708e7a1d1470",
                          posterSrc: "CVS_DLB1_TN.jpg",
                          listImage: "medication safety K-2.jpg",
                          listDescription:
                            "Los estudiantes conocerán el poder de los medicamentos y la importancia de utilizarlos de forma segura.",
                        },
                        {
                          title:
                            "El uso seguro de los medicamentos (3.º a 5.º)",
                          videoDuration: "4:28 minutos",
                          personDescription:
                            "Conoceremos a Kahra, una farmacéutica de CVS que ayudará a los estudiantes de primaria a entender las diferencias entre los medicamentos con receta y los de venta libre.",
                          gradeRange: "Grados 3.º a 5.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán sobre el poder de los medicamentos y la importancia de su uso seguro. Asimismo, compararán y contrastarán los medicamentos recetados con los de venta libre, y comprenderán qué hacer en caso de emergencia.",

                          actions: [
                            {
                              label: "Lección Digital",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Medication-Safety-Pharmacist-V5.0_ES.pptx",
                              logName: "MedicationSafety-Pharmacist.pptx ES",
                              type: "download",
                              size: "PPT 1.12GB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Guia para el farmacéutico",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MedicationSafety-Pharmacist_Guide.pdf",
                              logName: "MedicationSafety-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 7.4MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "fd53c516-14b9-4d4a-8459-c1c23a5d8c23",
                          posterSrc: "video-kahra-960x540.jpg",
                          listImage: "medication safety 3-5.jpg",
                          listDescription:
                            "Los estudiantes aprenderán sobre el poder de los medicamentos y la importancia de su uso seguro.",
                        },
                        {
                          title: "Control del estrés (3.º a 5.º)",
                          videoDuration: "5:50 minutos",
                          personDescription:
                            "Conoceremos a Jennifer, una farmacéutica de CVS que ayudará a los estudiantes a tener una conversación sobre salud mental, a entender qué es el estrés y, además, ofrecerá estrategias saludables y prácticas diarias para controlar el estrés en sus vidas.",
                          gradeRange: "Grados 3.º a 5.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Una vez que hayan aprendido los aspectos esenciales de la salud, los estudiantes se centrarán en la salud mental, analizando las causas y efectos del estrés. Podrán descubrir consejos y estrategias para favorecer su propia salud mental, y serán capaces de aplicarlos en las situaciones estresantes que puedan encontrar durante su vida.",
                          actions: [
                            {
                              type: "download",
                              label: "Lección Digital",
                              path: "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-ManagingStress-Pharmacist-V4.0_ES.pptx",
                              size: "PPT 753MB",
                              className: "episode-player__action-text",
                              logName: "Managing-Stress-Pharmacist.pptx",
                            },
                            {
                              type: "download",
                              label: "Guia para el farmacéutico",
                              path:
                                "/docs/CVS-Pharma-DLB-ManagingStress-Pharmacist-V3.0.pdf",
                              size: "PDF 5.2MB",
                              className: "episode-player__action-text",
                              logName: "Managing-Stress-Pharmacist.pdf",
                            },
                          ],
                          guidSrc: "c04dbc65-ce34-4b4a-b68e-d7492eb2dd72",
                          posterSrc: "CVS_DLB2_TN.jpg",
                          listImage: "managing stress.jpg",
                          listDescription:
                            "Una vez que hayan aprendido los aspectos esenciales de la salud, los estudiantes se centrarán en la salud mental, analizando las causas y efectos del estrés.",
                        },
                        {
                          title: "Todo sobre los medicamentos (3.º a 5.º)",
                          videoDuration: "5:58 minutos",
                          personDescription:
                            "Conoceremos a Ro, un farmacéutico de CVS que hablará sobre el poder de los medicamentos, analizando cómo se descubrieron los medicamentos con receta, cómo viajan por nuestro cuerpo y por qué los medicamentos vienen en distintas presentaciones.",
                          gradeRange: "Grados 3.º a 5.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes estudiarán cómo se crean los medicamentos, cómo se administran, cómo viajan por el cuerpo y el porqué de sus múltiples presentaciones.",
                          actions: [
                            {
                              type: "download",
                              label: "Lección Digital",
                              path: "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-AllAboutMedication-Pharmacist-V2.0_ES.pptx",
                              size: "PPT 56.3MB",
                              className: "episode-player__action-text",
                              logName: "All-About-Medication-Pharmacist.pptx",
                            },
                            {
                              type: "download",
                              label: "Guia para el farmacéutico",
                              path:
                                "/docs/CVS-Pharma-DLB-AllAboutMedication-Pharmacist-V1.1.pdf",
                              size: "PDF 6.3MB",
                              className: "episode-player__action-text",
                              logName: "All-About-Medication-Pharmacist.pdf",
                            },
                          ],
                          guidSrc: "99fda6b5-a752-463b-a330-8c103ff0b323",
                          posterSrc: "CVS_DLB3_TN.jpg",
                          listImage: "all about medication.jpg",
                          listDescription:
                            "Los estudiantes estudiarán cómo se crean los medicamentos, cómo se administran, cómo viajan por el cuerpo y el porqué de sus múltiples presentaciones.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************** Grados 6-8 DLBs *****************/}
              <Section className="midgray-background" id="6-8">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grados 6.º a 8.º"
                      episodes={[
                        {
                          title: "Salud mental (6.º a 12.º)",
                          videoDuration: "4:47 minutos",
                          personDescription:
                            "Conoceremos a James, un farmacéutico de CVS que hablará a los estudiantes sobre las benzodiacepinas, una clase de medicamentos que se pueden utilizar para tratar los casos más severos de ansiedad, y el peligro que pueden suponer si se utilizan mal o se combinan con otras sustancias.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo. Los estudiantes aprenderán sobre el papel que los medicamentos recetados pueden desempeñar para el tratamiento de la ansiedad y la depresión y analizarán los riesgos asociados con el uso indebido de medicamentos recetados u otras sustancias.",
                          actions: [
                            {
                              label: "Lección Digital",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MentalHealth-v3.2-Pharmacist_ES.pptx",
                              logName: "MentalHealth-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 575MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Guia para el farmacéutico",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist_Guide.pdf",
                              logName: "MentalHealth-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 10MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                          posterSrc: "video-james-960x540.jpg",
                          listImage: "mental health.jpg",
                          listDescription:
                            "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo.",
                        },
                        {
                          title: "La presión de los compañeros (6.º a 12.º)",
                          videoDuration: "4:24 minutos",
                          personDescription:
                            "Conoceremos a Lance, un farmacéutico de CVS que hablará con los estudiantes sobre la presión de los pares, las estrategias de rechazo y cómo lograr un impacto positivo en sus compañeros.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "En este Módulo de lecciones digitales, los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                          actions: [
                            {
                              label:
                                "Lección Digital (para el aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Peer-Pressure-Pharmacist-Classroom-ES.pptx",
                              logName:
                                "Responding to Peer Pressure.pptx - Pharmacists - Classroom - ES",
                              type: "download",
                              size: "PPT 17.9MB",
                              className: "episode-player__action-text",
                            }
                          ],
                          guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                          posterSrc: "img-toolkit-video-peer-pressure.jpg",
                          listImage: "peer preassure.jpg",
                          listDescription:
                            "Los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                        },
                        {
                          title: "Estimulantes recetados (6.º a 12.º)",
                          videoDuration: "4:05 minutos",
                          personDescription:
                            "Conoceremos a Tyler, un farmacéutico de CVS que les hablará a los estudiantes sobre los estimulantes con receta, cómo funcionan y por qué su uso indebido puede ser peligroso.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH. Los estudiantes analizarán los peligros asociados con el uso indebido de estimulantes recetados, los efectos que el uso indebido puede tener en el cerebro y en el cuerpo, e identificarán a dónde pueden acudir en caso de necesitar ayuda.",
                          actions: [
                            {
                              label: "Lección Digital",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Prescription-Stimulants-Pharmacist_ES.pptx",
                              logName: "PrescriptionStimulants-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 439MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Guia para el farmacéutico",
                              path:
                                "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist_Guide.pdf",
                              logName:
                                "PrescriptionStimulants-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 6.1MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                          posterSrc: "video-tyler-960x540.jpg",
                          listImage: "prescription stimulants.jpg",
                          listDescription:
                            "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH.",
                        },
                        {
                          title: "Uso y abuso de los opioides (6.º a 8.º)",
                          videoDuration: "5:08 minutos",
                          personDescription:
                            "Conoceremos a Vicki, una farmacéutica de CVS que conversará con los estudiantes sobre cómo brindar apoyo a quienes tienen problemas con el abuso de sustancias o padecen un trastorno por consumo de sustancias.",
                          gradeRange: "Grados 6.º a 8.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán sobre los opioides, los efectos de estas drogas en el cerebro humano y los efectos similares a las endorfinas en el cuerpo humano. Los estudiantes harán una comparación sobre las diferencias entre los medicamentos recetados y las drogas ilícitas.",
                          actions: [
                            {
                              label:
                                "Lección Digital (para el aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Opioid-Use-And-Misuse-Pharmacist-Classroom-ES.pptx",
                              logName:
                                "Opioid Use and Misuse.pptx - Pharmacists - Classroom",
                              type: "download",
                              size: "PPT 7.7MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Lección Digital (para fuera del aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Opioid-Use-And-Miuse-Pharmacist-Non-Classroom-ES.pptx",
                              logName:
                                "Opioid Use and Misuse.pptx - Pharmacists - Non-Classroom",
                              className: "max__width",
                              type: "download",
                              size: "PPT 5.8MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "2526ede5-f86a-4e83-bf5f-6f318b69c9f9",
                          posterSrc: "img-toolkit-video-help-around.jpg",
                          listImage: "opioid use and misuse.jpg",
                          listDescription:
                            "Los estudiantes aprenderán sobre los opioides, los efectos de estas drogas en el cerebro humano y los efectos similares a las endorfinas en el cuerpo humano.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>

              {/*************** Grados 9-12 DLBs *****************/}
              <Section className="midgray-background" id="9-12">
                <Container fullWidth>
                  <Row>
                    <EpisodePlayer
                      locked={isAuthenticated && isClient ? false : true}
                      playerLabelTitle="Grados 9.º a 12.º"
                      episodes={[
                        {
                          title: "Salud mental (6.º a 12.º)",
                          videoDuration: "4:47 minutos",
                          personDescription:
                            "Conoceremos a James, un farmacéutico de CVS que hablará a los estudiantes sobre las benzodiacepinas, una clase de medicamentos que se pueden utilizar para tratar los casos más severos de ansiedad, y el peligro que pueden suponer si se utilizan mal o se combinan con otras sustancias.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo. Los estudiantes aprenderán sobre el papel que los medicamentos recetados pueden desempeñar para el tratamiento de la ansiedad y la depresión y analizarán los riesgos asociados con el uso indebido de medicamentos recetados u otras sustancias.",
                          actions: [
                            {
                              label: "Lección Digital",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-MentalHealth-v3.2-Pharmacist_ES.pptx",
                              logName: "MentalHealth-Pharmacist.pptx ES",
                              type: "download",
                              size: "PPT 602MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Guia para el farmacéutico",
                              path:
                                "/files/docs/CVS-Dose-of-Knowledge-DLB-MentalHealth-Pharmacist_Guide.pdf",
                              logName: "MentalHealth-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 10MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "24af7a68-abb2-4cae-8d53-6c27a18401d4",
                          posterSrc: "video-james-960x540.jpg",
                          listImage: "mental health.jpg",
                          listDescription:
                            "Los estudiantes aprenderán que la salud mental es un componente clave de la salud general, repasarán estrategias para cuidar su salud mental y estudiarán los efectos que el estrés, la ansiedad y la depresión pueden tener en el cerebro y el cuerpo.",
                        },
                        {
                          title: "La presión de los compañeros (6.º a 12.º)",
                          videoDuration: "4:24 minutos",
                          personDescription:
                            "Conoceremos a Lance, un farmacéutico de CVS que hablará con los estudiantes sobre la presión de los pares, las estrategias de rechazo y cómo lograr un impacto positivo en sus compañeros.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "En este Módulo de lecciones digitales, los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                          actions: [
                            {
                              label:
                                "Lección Digital (para el aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-Peer-Pressure-Pharmacist-Classroom-ES.pptx",
                              logName:
                                "Responding to Peer Pressure.pptx - Pharmacists - Classroom - ES",
                              type: "download",
                              size: "PPT 17.9MB",
                              className: "episode-player__action-text",
                            }
                          ],
                          guidSrc: "d5571f7e-7b08-4366-886a-5711011fcc8f",
                          posterSrc: "img-toolkit-video-peer-pressure.jpg",
                          listImage: "peer preassure.jpg",
                          listDescription:
                            "Los estudiantes elaborarán y practicarán juntos habilidades de rechazo y estrategias de salida para aplicarlas cuando se les ofrezcan opioides u otras drogas.",
                        },
                        {
                          title: "Estimulantes recetados (6.º a 12.º)",
                          videoDuration: "4:05 minutos",
                          personDescription:
                            "Conoceremos a Tyler, un farmacéutico de CVS que les hablará a los estudiantes sobre los estimulantes con receta, cómo funcionan y por qué su uso indebido puede ser peligroso.",
                          gradeRange: "Grados 6.º a 12.º",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH. Los estudiantes analizarán los peligros asociados con el uso indebido de estimulantes recetados, los efectos que el uso indebido puede tener en el cerebro y en el cuerpo, e identificarán a dónde pueden acudir en caso de necesitar ayuda.",
                          actions: [
                            {
                              label: "Lección Digital",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-Pharma-DLB-Prescription-Stimulants-Pharmacist_ES.pptx",
                              logName: "PrescriptionStimulants-Pharmacist.pptx",
                              type: "download",
                              size: "PPT 440MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label: "Guia para el farmacéutico",
                              path:
                                "/docs/CVS-Dose-of-Knowledge-DLB-PrescriptionStimulants-Pharmacist_Guide.pdf",
                              logName:
                                "PrescriptionStimulants-Pharmacist Guide.pdf",
                              type: "download",
                              size: "PDF 6.1MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "ed3a6b3a-d1e4-4550-bec5-a3cb2c554f3e",
                          posterSrc: "video-tyler-960x540.jpg",
                          listImage: "prescription stimulants.jpg",
                          listDescription:
                            "Los estudiantes aprenderán sobre los estimulantes recetados y cómo se utilizan en el tratamiento del TDAH.",
                        },
                        {
                          title: "Los opioides y el cerebro (9.º a 12.º)",
                          videoDuration: "3:53 minutos",
                          personDescription:
                            "Conoceremos a Fernando, un farmacéutico de CVS que hablará con los estudiantes sobre los opioides, el riesgo asociado al consumo de sustancias y lo que deben hacer si se les receta un opioide.",
                          gradeRange: "Grados 9–12",
                          lessonDuration: "45-60 minutos",
                          lessonDescription:
                            "Los estudiantes recibirán un resumen general de lo que son los opioides y examinarán sus efectos a corto y largo plazo en el cerebro y el cuerpo. Aprenderán sobre el abuso de sustancias y el trastorno por consumo de sustancias.",
                          actions: [
                            {
                              label:
                                "Lección Digital (para el aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-OPIOIDS-AND-BRAIN-V11-Pharmacist-Classroom-ES.pptx",
                              logName:
                                "Opioids and the Brain.pptx - Pharmacists - Classroom - ES",
                              type: "download",
                              size: "PPT 37.8MB",
                              className: "episode-player__action-text",
                            },
                            {
                              label:
                                "Lección Digital (para fuera del aula)",
                              path:
                                "https://d2nso076wm43cb.cloudfront.net/CVS-Pharma/CVS-DoK-OPIOIDS-AND-BRAIN-V11-Pharmacist-Non-Classroom-ES.pptx",
                              logName:
                                "Opioids and the Brain.pptx - Pharmacists - Non-Classroom - ES",
                              className: "max__width",
                              type: "download",
                              size: "PPT 9.7MB",
                              className: "episode-player__action-text",
                            },
                          ],
                          guidSrc: "4d8892c2-8bb3-4e13-a6d4-3272f313bcae",
                          posterSrc: "img-toolkit-video-opioids-effects.jpg",
                          listImage: "opiods and brain.jpg",
                          listDescription:
                            "Los estudiantes recibirán un resumen general de lo que son los opioides y examinarán sus efectos a corto y largo plazo en el cerebro y el cuerpo.",
                        },
                      ]}
                    />
                  </Row>
                </Container>
              </Section>
            </>
          )}
        </AuthConsumer>
        {/***************  ABOVE FOOTER CALLOUT ***************/}
        <Section className="subfooter">
          <Container>
            <Row>
              <Column span={4}>
                <img
                  src={SubFooterImage}
                  className="subfooter-image"
                  alt="About Pharmacists Teach"
                />
              </Column>
              <Column span={8}>
                <h3>Respecto al programa Los farmacéuticos educan:</h3>
                <p>
                  CVS Health creó el programa 'Los farmacéuticos educan' en 2015
                  para llevar a los farmacéuticos a las aulas con el fin de
                  educar al público sobre la problemática en torno al abuso de
                  sustancias. El programa Los farmacéuticos educan ahora se ha
                  ampliado e incluye Dose of Knowledge (Una dosis de
                  conocimiento), un programa creado en colaboración con
                  Discovery Education que ofrece recursos alineados con los
                  estándares a educadores y farmacéuticos de todo Estados
                  Unidos. El programa Los farmacéuticos educan se propone llegar
                  a casi 2 millones de estudiantes en 2023 y capacitar a los
                  estudiantes de todo el país para que tomen decisiones
                  saludables y eviten el uso indebido de medicamentos recetados
                  e ilegales.
                </p>
                <OutboundLink to="https://cvshealth.com/social-responsibility/corporate-social-responsibility/pharmacists-teach">
                  <button className="global__button black">
                    Obtener más información
                  </button>
                </OutboundLink>
              </Column>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default PharmacistsSpanishPage
